import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    }
  },
  details: {
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2.5),
      position: "sticky"
    }
  }
}))

const SingleBlog = ({ data }) => {
  const classes = useStyles()
  const { portfolioName, portfolioArea, portfolioProject, portfolioLocation, images = [] } = (data && data.datoCmsPortfolio) || {}
  return (
    <Layout logo="black">
      <Seo title={`Portfolio - ${portfolioName}`} description="Portfolio" />
        <Container maxWidth="lg">
          <Grid container spacing={6} className={classes.grid}>
            <Grid item sm={5} xs={12}>
              <Box className={classes.details}>
                <Typography variant="h5" gutterBottom><strong>{portfolioName}</strong></Typography>
                {portfolioLocation && (
                  <Typography variant="body2">{`Lokalizacja: ${portfolioLocation}`}</Typography>
                )}
                {portfolioArea && (
                  <Typography variant="body2">{`Powierzchnia: ${portfolioArea}`}</Typography>
                )}
                {portfolioProject && (
                  <Typography variant="body2">{`Projekt/realizacja: ${portfolioProject}`}</Typography>
                )}
                <Typography variant="body2">Zdjęcia/wizualizacje:</Typography>
              </Box>
            </Grid>
            <Grid item sm={7} xs={12}>
              <Grid container spacing={1} item>
                {images.map((image, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <GatsbyImage image={getImage(image)} alt={`Portfolio ${index + 1}`}/>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
    </Layout>
  )
}

export const query = graphql`
  query QuerySinglePortfolio($id: String!) {
    datoCmsPortfolio(id: { eq: $id }) {
      id
      portfolioName
      portfolioArea
      portfolioProject
      portfolioLocation
      images {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default SingleBlog
